import React, { useEffect, useRef, useState } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AddWorkoutPopup from "./AddWorkoutPopup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import EditWorkoutPopup from "./EditWorkoutPopup";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  add_program_workout_api,
  delete_program_workout_Api,
  delete_program_workout_details_Api,
  programme_workout_list_api,
  reorder_events_api,
  update_program_workout_api,
} from "src/DAL/programmeWorkout/programmeWorkout";
import { useSnackbar } from "notistack";
import ConfirmationWithDescription from "src/components/ModalPopover/ConfirmationWithDescription";
import moment from "moment";
import { Icon } from "@iconify/react";
import { s3baseUrl } from "src/config/config";

const DynamicTable = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { slug } = useParams();
  const Table = ({ numRows, numCols }) => {
    const [hoveredCell, setHoveredCell] = useState(null);
    const [drawerState, setDrawerState] = useState(false);
    const [editItrationDrawerState, setEditItrationDrawerState] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [editWorkputData, setEditWorkputData] = useState();
    const [dataDelMember, setDataDelMember] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedDate, setSelectDate] = useState("");
    const [dataDel, setDataDel] = useState();
    const [workouts, setworkouts] = useState([]);
    const [dataCopy, setDataCopy] = useState();
    const [effectedMembers, setEffectedMembers] = useState(state?.members);
    const [pasteDate, setPasteDate] = useState();
    const [sortedIds, setSortedIds] = useState([]);

    const calendarRef = useRef(null);
    const [tableData, setTableData] = useState(
      Array(numRows).fill(Array(numCols).fill(""))
    );
    const [openDragConfig, setOpenDragConfig] = useState(false);
    const [DragConfigDate, setDragConfigDate] = useState(false);
    const [DragConfigData, setDragConfigData] = useState(false);
    const [openCopyPasteConfig, setOpenCopyPasteConfig] = useState(false);
    const handleCellHover = (rowIndex, colIndex) => {
      setHoveredCell({ rowIndex, colIndex });
    };
    const handleCellLeave = () => {
      setHoveredCell(null);
    };
    const handleAgreeDelete = async (data) => {
      setDataDel(data);
      setOpenDelete(true);
      // const result = await delete_program_workout_details_Api(data?._id);
      // if (result.code === 200) {
      //   setDataDelMember(result.members_count);

      // } else {
      //   enqueueSnackbar(result.message, { variant: "error" });
      // }
    };
    const handleDeleteEvent = (eventToDelete) => {
      // Handle deletion logic here
    };
    const handleDelete = async (value) => {
      setOpenDelete(false);

      const result = await delete_program_workout_Api(
        dataDel?.program_workout_slug
      );
      if (result.code === 200) {
        setEditWorkputData();

        enqueueSnackbar(result.message, { variant: "success" });
        let updatedEvents = workouts.filter(
          (item) => item.program_workout_slug != dataDel.program_workout_slug
        );
        setworkouts(updatedEvents);
        // getWorkoutListing();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };
    const handleChange = (rowIndex, colIndex, value) => {
      const newData = tableData.map((row, i) =>
        i === rowIndex
          ? row.map((col, j) => (j === colIndex ? value : col))
          : row
      );
      setTableData(newData);
    };
    const handleOpenDrawer = (week, day) => {
      let date = (week - 1) * 7 + day;
      setSelectDate(date);
      setDrawerState(true);
    };
    const handleCopyExercise = (data) => {
      setDataCopy(data);
    };
    const handlePaste = async (week, day) => {
      // setSelectDate(arg);
      setPasteDate((week - 1) * 7 + day);
      setOpenCopyPasteConfig(true);
    };
    const handleOpenEditIterationDrawer = () => {
      setEditItrationDrawerState(true);
    };
    const handleUpdateIteration = (data) => {
      setEditWorkputData(data);
      setSelectDate(data?.workout_day);
      handleOpenEditIterationDrawer();
    };
    const handleReorder = async (newevents) => {
      // const ids = newevents.map((event) => event._id);
      let postData = {
        workoutIds: newevents,
      };
      const result = await reorder_events_api(postData);
      if (result.code === 200) {
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };
    const EventCard = ({ data, setData, index, onDelete, onHover }) => {
      const [isHovered, setIsHovered] = useState(false);

      const timeoutRef = useRef(null);

      useEffect(() => {
        // Cleanup function to clear the timeout on component unmount
        return () => {
          clearTimeout(timeoutRef.current);
        };
      }, []);
      const formatWithLeadingZeros = (value) => {
        return value < 10 ? `0${value}` : `${value}`;
      };
      const getMinutesForInterval = (mainindex, index) => {
        const list = [...data?.exercises];
        const interval = list[mainindex].interval_duration;
        if (interval <= 60) {
          return index + 1;
        }
        if (interval % 60 == 0) {
          return `${
            (interval / 60) * index +
            1 +
            " – " +
            ((interval / 60) * index + interval / 60)
          }`;
        }
        let startInterval = interval * index;
        let endtInterval = interval * index + interval;
        let startintervalText =
          interval * index
            ? Math.floor(startInterval / 60) +
              " " +
              ":" +
              formatWithLeadingZeros(startInterval % 60)
            : 0;
        let endIntervalText =
          Math.floor(endtInterval / 60) +
          " " +
          ":" +
          formatWithLeadingZeros(endtInterval % 60);

        return `${startintervalText + " – " + endIntervalText}`;
      };
      const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current); // Clear any existing timeout
        setIsHovered(true);
      };

      const isFirstEventOnDate = (workout) => {
        const sameDayWorkouts = workouts.filter(
          (w) => w.workout_day === workout.workout_day
        );
        return sameDayWorkouts[0]._id === workout._id;
      };

      const isLastEventOnDate = (workout) => {
        const sameDayWorkouts = workouts.filter(
          (w) => w.workout_day === workout.workout_day
        );
        return sameDayWorkouts[sameDayWorkouts.length - 1]._id === workout._id;
      };
      const updateSortedIds = (workoutDay, updatedWorkouts) => {
        const sortedIds = updatedWorkouts
          .filter((w) => w.workout_day === workoutDay)
          .map((w) => w._id);
        setSortedIds(sortedIds);
        console.log("Updated sorted IDs:", sortedIds);
        handleReorder(sortedIds);
      };
      const moveEventUp = (workout) => {
        setEditWorkputData(workout);
        const sameDayWorkouts = workouts.filter(
          (w) => w.workout_day === workout.workout_day
        );
        const workoutIndex = sameDayWorkouts.findIndex(
          (w) => w._id === workout._id
        );
        if (workoutIndex > 0) {
          const newWorkouts = [...workouts];
          const prevWorkout = sameDayWorkouts[workoutIndex - 1];
          const currentWorkoutIndex = workouts.findIndex(
            (w) => w._id === workout._id
          );
          const prevWorkoutIndex = workouts.findIndex(
            (w) => w._id === prevWorkout._id
          );
          newWorkouts[currentWorkoutIndex] = prevWorkout;
          newWorkouts[prevWorkoutIndex] = workout;
          setworkouts(newWorkouts);
          updateSortedIds(workout.workout_day, newWorkouts);
        }
      };

      const moveEventDown = (workout) => {
        setEditWorkputData(workout);

        const sameDayWorkouts = workouts.filter(
          (w) => w.workout_day === workout.workout_day
        );
        const workoutIndex = sameDayWorkouts.findIndex(
          (w) => w._id === workout._id
        );
        if (workoutIndex < sameDayWorkouts.length - 1) {
          const newWorkouts = [...workouts];
          const nextWorkout = sameDayWorkouts[workoutIndex + 1];
          const currentWorkoutIndex = workouts.findIndex(
            (w) => w._id === workout._id
          );
          const nextWorkoutIndex = workouts.findIndex(
            (w) => w._id === nextWorkout._id
          );
          newWorkouts[currentWorkoutIndex] = nextWorkout;
          newWorkouts[nextWorkoutIndex] = workout;
          setworkouts(newWorkouts);
          updateSortedIds(workout.workout_day, newWorkouts);
        }
      };
      const handleMouseLeave = () => {
        // Introduce a delay of 100 milliseconds before updating state on hover out
        const delay = 300;
        timeoutRef.current = setTimeout(() => {
          setIsHovered(false);
        }, delay);
      };

      return (
        <div
          className="calendar-workout-card my-4"
          style={{
            border:
              editWorkputData?._id == data?._id
                ? "2px solid gold"
                : "0px solid gold",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div className="w-100 h-100 " onMouseEnter={handleMouseEnter}>
            {isHovered && (
              <div className="all-icon-calendar-workout-general d-flex w-100 justify-content-between">
                <div className="d-flex">
                  <Tooltip
                    title={`${
                      data?.workout_loggers.length == 0
                        ? "Delete"
                        : "Can't be deleted, This workout has results."
                    }`}>
                    <DeleteIcon
                      className={`${
                        data?.workout_loggers.length == 0
                          ? "del-icon-calendar-workout-general "
                          : "del-icon-calendar-workout-disabled-general"
                      }`}
                      onClick={
                        data?.workout_loggers.length == 0
                          ? () => handleAgreeDelete(data)
                          : () => {}
                      }
                    />
                  </Tooltip>
                  <Tooltip title={`${"Copy"}`}>
                    <div
                      className={`${"copy-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center"}`}>
                      <ContentCopyIcon
                        sx={{ width: 17, height: 17 }}
                        onClick={() => handleCopyExercise(data)}
                      />
                    </div>
                  </Tooltip>
                </div>{" "}
                <div className="d-flex">
                  {" "}
                  <Tooltip title="Move Up">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton disabled={isFirstEventOnDate(data)}>
                        <KeyboardArrowUpIcon
                          sx={{ width: 17, height: 17 }}
                          onClick={() => moveEventUp(data)}
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                  <Tooltip title="Move Down">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton disabled={isLastEventOnDate(data)}>
                        <KeyboardArrowDownIcon
                          sx={{ width: 17, height: 17 }}
                          onClick={() => moveEventDown(data)}
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                </div>
              </div>
            )}
          </div>

          <div onMouseEnter={handleMouseEnter}>
            <div
              className="calendar-workout-card-title"
              style={{
                backgroundColor: data?.program_info?.color,
              }}
              onMouseEnter={handleMouseEnter}>
              <div
                onMouseEnter={handleMouseEnter}
                className="calendar-workout-card-program">
                {data?.program_info?.title}
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className="calendar-workout-card-workout"
                  onClick={() => handleUpdateIteration(data)}
                  onMouseEnter={handleMouseEnter}>
                  {data?.title}
                </div>
                <div className="d-flex align-items-center">
                  <Icon
                    icon={
                      data.practice_type == "workout"
                        ? "mdi:gym"
                        : data.practice_type == "food"
                        ? "fluent:food-24-filled"
                        : data.practice_type == "mindset"
                        ? "flowbite:brain-outline"
                        : data.practice_type == "video"
                        ? "lets-icons:video-fill"
                        : ""
                    }
                    className="workout-type-icon-container "
                  />
                  {!data?.open ? (
                    <KeyboardArrowDownIcon
                      sx={{
                        cursor: "pointer",
                        opacity: 0.7,
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        const list = [...workouts];
                        list[index]["open"] = true;
                        setData(list);
                      }}
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      sx={{
                        cursor: "pointer",
                        opacity: 0.7,
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        const list = [...workouts];
                        list[index]["open"] = false;
                        setData(list);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {data?.open && (
              <div className="calendar-workout-card-exercises">
                <div
                  className="calendar-workout-card-container"
                  onMouseEnter={handleMouseEnter}
                  onClick={() => handleUpdateIteration(data)}>
                  {/* {data?.exercises.map((exercise) => {
                    return (
                      <div
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {exercise?.title}
                      </div>
                    );
                  })} */}
                  {data?.practice_type == "video" ? (
                    <>
                      <div className="inner-div workout-calendar-exercise-card my-1">
                        {data?.dumyImage ? (
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>
                            <img
                              className="image-border"
                              src={data?.dumyImage}
                              height="50"
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>
                            <img
                              className="image-border"
                              src={
                                s3baseUrl + data?.video_thumbnail?.thumbnail_1
                              }
                              height="50"
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {data?.exercises.map((exercise, index) => {
                    if (exercise?.exercise_type == "something_else") {
                      return (
                        <div className="inner-div workout-calendar-exercise-card my-1">
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                            }}>
                            {exercise?.title}
                          </div>
                        </div>
                      );
                    } else if (
                      exercise?.exercise_type == "amrap" &&
                      exercise?.emom_amrap &&
                      exercise?.emom_amrap.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          <div className="text-center">AMRAP</div>
                          {exercise?.emom_amrap?.map(
                            (interval, intervalIndex) => {
                              return (
                                <div className="my-1">
                                  <div style={{ fontSize: "8px" }}>
                                    Movements
                                  </div>
                                  {interval?.map((exerData) => {
                                    return (
                                      <div
                                        className="inner-div"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          marginBottom: "3px",
                                          // marginBottom: "1px",
                                          // backgroundColor:
                                          //   "rgba(255, 255, 255,255)",
                                          // overflow: "hidden",
                                          // textOverflow: "ellipsis",
                                        }}>
                                        {exerData?.exercise_info?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (
                      exercise?.exercise_type == "emom" &&
                      exercise?.emom_amrap &&
                      exercise?.emom_amrap.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          <div className="text-center">EMOM</div>
                          {exercise?.emom_amrap?.map(
                            (interval, intervalIndex) => {
                              return (
                                <div className="my-1">
                                  <div style={{ fontSize: "8px" }}>
                                    Minute{" "}
                                    {getMinutesForInterval(
                                      index,
                                      intervalIndex
                                    )}
                                  </div>
                                  {interval?.map((exerData) => {
                                    return (
                                      <div
                                        className="inner-div"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          marginBottom: "3px",
                                          // marginBottom: "1px",
                                          // backgroundColor:
                                          //   "rgba(255, 255, 255,255)",
                                          // overflow: "hidden",
                                          // textOverflow: "ellipsis",
                                        }}>
                                        {exerData?.exercise_info?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (
                      exercise?.sub_exercises &&
                      exercise?.sub_exercises.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          {exercise?.sub_exercises?.map(
                            (exerData, exerIndex) => {
                              return (
                                <div
                                  className="inner-div"
                                  style={{
                                    whiteSpace: "break-spaces",
                                    marginBottom: "3px",
                                    // overflow: "hidden",
                                    // textOverflow: "ellipsis",
                                  }}>
                                  {exerData?.exercise_info?.title}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else
                      return (
                        <div className="inner-div workout-calendar-exercise-card my-1">
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                            }}>
                            {exercise?.exercise_info?.title}
                          </div>
                        </div>
                      );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    };
    const getWorkoutListing = async () => {
      let postData = {
        start_date: 0,
        end_date: parseInt(state?.no_of_weeks) * 7,
        slug: slug,
      };

      const result = await programme_workout_list_api(postData);
      if (result.code === 200) {
        const modifiedArray = result?.program_workouts.map((item) => {
          return {
            ...item,
            open: true,
            editable: item?.workout_loggers.length > 0 ? false : true,
          };
        });
        setworkouts(modifiedArray);
        // setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        // setIsLoading(false);
      }
    };

    useEffect(() => {
      getWorkoutListing();
    }, []);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setDataCopy("");
      }, 15000);

      return () => clearTimeout(timeoutId);
    }, []);
    const closeDragConfig = async (value) => {
      setOpenDragConfig(false);
      getWorkoutListing();
    };
    const closeCopyPasteConfig = async (value) => {
      setOpenCopyPasteConfig(false);
      setDataCopy("");
      getWorkoutListing();
      // reloadList();
    };
    const handleCopyPaste = async (value) => {
      setOpenCopyPasteConfig(false);
      console.log(dataCopy, "adfkakjdkfakj");
      const formDataObject = {
        title: dataCopy?.title,
        workout_type: "",
        description: dataCopy?.description,
        status: true,
        exercises: dataCopy?.exercises,
        program_id: dataCopy?.program_info?.program_id,
        workout_day: pasteDate,
        practice_type: dataCopy?.practice_type,
        video_url: dataCopy?.video_url,
        video_thumbnail: dataCopy?.video_thumbnail,
      };
      const formData = new FormData();
      formData.append("title", formDataObject.title);
      formData.append("workout_type", formDataObject.workout_type);
      formData.append("description", formDataObject.description);
      formData.append(
        "video_url",
        formDataObject.video_url ? formDataObject.video_url : ""
      );
      if (formDataObject.video_thumbnail) {
        formData.append(
          "duplicate_image_path",
          JSON.stringify(formDataObject.video_thumbnail)
        );
      }
      formData.append("status", formDataObject.status);
      formData.append("exercises", JSON.stringify(formDataObject.exercises));
      formData.append("program_id", formDataObject.program_id);
      formData.append("workout_day", formDataObject.workout_day);
      formData.append("practice_type", formDataObject.practice_type);
      console.log(formDataObject, "kdfkjdskjfjkskjjjdjubfmk");
      const result = await add_program_workout_api(formData);
      if (result.code === 200) {
        setEditWorkputData(result?.workout);
        enqueueSnackbar(result.message, { variant: "success" });
        setDataCopy("");
        const newWorkout = result?.workout;
        let newData = {
          ...newWorkout,
          title: newWorkout.title,
          color: newWorkout.program_info?.color,
          workout_color: newWorkout.program_info?.color,
          workout_title: newWorkout.title,
          workout_exercises: newWorkout.exercises,
          workout_program: newWorkout.program_info,
          status: newWorkout.status,
          is_deleteAble: newWorkout?.workout_loggers.length > 0 ? false : true,
          date: moment(newWorkout.workout_date).format("YYYY-MM-DD"),
          index: workouts.length - 1,
          open: true,
          editable: newWorkout?.workout_loggers.length > 0 ? false : true,
        };
        let list = [...workouts, newData];
        setworkouts(list);
        // getWorkoutListing();
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setDataCopy("");
      }
    };
    const handleDraged = async (value) => {
      setOpenDragConfig(false);
      console.log(DragConfigDate, DragConfigData, "dlkjfkdsjfakslja");
      const formDataObject = {
        title: DragConfigData?.title,
        description: DragConfigData?.description,
        exercises: DragConfigData?.exercises,
        workout_type: "",
        status: true,
        workout_day: DragConfigDate,
        video_url: DragConfigData?.video_url,
        video_thumbnail: DragConfigData?.video_thumbnail,
      };
      const formData = new FormData();

      formData.append("title", formDataObject.title);
      formData.append("description", formDataObject.description);
      formData.append("exercises", JSON.stringify(formDataObject.exercises));
      formData.append("workout_type", formDataObject.workout_type);
      formData.append("status", formDataObject.status);
      formData.append("workout_day", formDataObject.workout_day);
      formData.append(
        "video_url",
        formDataObject.video_url ? formDataObject.video_url : ""
      );
      formData.append(
        "duplicate_image_path",
        JSON.stringify(formDataObject.video_thumbnail)
      );
      console.log(formDataObject, "dslfkdjsaa");
      const result = await update_program_workout_api(
        formData,
        DragConfigData?.program_workout_slug
      );

      if (result.code === 200) {
        setEditWorkputData(DragConfigData);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        getWorkoutListing();
      }
    };
    const drag = (event, data) => {
      event.dataTransfer.setData("text/plain", JSON.stringify(data));
      event.target.style.opacity = "0.01";
    };
    const dragEnd = (event, data) => {
      event.dataTransfer.setData("text/plain", JSON.stringify(data));
      event.target.style.opacity = "";
    };
    const drop = (event, rowIndex, colIndex) => {
      event.preventDefault();
      const data = JSON.parse(event.dataTransfer.getData("text/plain"));
      const modifiedArray = workouts.map((item) => {
        if (item._id === data?._id) {
          return { ...item, workout_day: rowIndex * 7 + (colIndex + 1) };
        }
        return item;
      });
      setworkouts(modifiedArray);
      setOpenDragConfig(true);
      setDragConfigDate(rowIndex * 7 + (colIndex + 1));
      setDragConfigData(data);
    };

    return (
      <div className="container">
        <AddWorkoutPopup
          open={drawerState}
          setOpen={setDrawerState}
          day={selectedDate}
          programme={state}
          reloadList={getWorkoutListing}
          setEditWorkputData={setEditWorkputData}
          events={workouts}
          setEvents={setworkouts}
        />

        <EditWorkoutPopup
          open={editItrationDrawerState}
          setOpen={setEditItrationDrawerState}
          day={selectedDate}
          data={editWorkputData}
          programme={state}
          reloadList={getWorkoutListing}
          events={workouts}
          setEvents={setworkouts}
        />
        <ConfirmationWithDescription
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to Delete?"}
          descriotion={`If you take action it will delete from ${effectedMembers} users.`}
          handleAgree={handleDelete}
        />
        <ConfirmationWithDescription
          open={openDragConfig}
          setOpen={closeDragConfig}
          title={"Do you want to take this action?"}
          descriotion={`It will impact ${effectedMembers} users, who has access to this program.`}
          handleAgree={handleDraged}
        />
        <ConfirmationWithDescription
          open={openCopyPasteConfig}
          setOpen={closeCopyPasteConfig}
          title={"Do you want to paste it here?"}
          descriotion={`It will impact ${effectedMembers} users, who has access to this program.`}
          handleAgree={handleCopyPaste}
        />

        <table className="fixedTable" id="dragTable">
          <thead>
            <tr>
              {[...Array(numCols)].map((_, index) => (
                <th className="fixed-table-head" key={index}>
                  <div className="mt-2 mb-2" style={{ opacity: 0.7 }}>
                    Day {index + 1}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex} className="">
                {row.map((col, colIndex) => (
                  <td
                    className="fixed-table-data"
                    key={colIndex}
                    onMouseEnter={() => handleCellHover(rowIndex, colIndex)}
                    onMouseLeave={handleCellLeave}
                    onDrop={(event) => drop(event, rowIndex, colIndex)}
                    onDragOver={(event) => event.preventDefault()}>
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                        marginTop: 15,
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          ml: 0.5,
                          mt: 2,
                          opacity: 0.6,
                        }}
                        variant="body2">
                        Week {rowIndex + 1}
                      </Typography>
                      <div style={{ minHeight: "100px" }}>
                        {hoveredCell &&
                          hoveredCell.rowIndex === rowIndex &&
                          hoveredCell.colIndex === colIndex && (
                            <div className="cell-icon-workout d-flex text-end w-100">
                              {dataCopy && (
                                <Tooltip
                                  touch="on"
                                  placement="bottom"
                                  title={"Paste"}>
                                  <div className="paste-icon-workout-general me-1">
                                    <AssignmentTurnedInIcon
                                      onClick={() =>
                                        handlePaste(rowIndex + 1, colIndex + 1)
                                      }
                                      sx={{
                                        cursor: "pointer",
                                        height: 15,
                                        width: 15,
                                        mb: "2px",
                                        mr: "2px",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              )}

                              <AddIcon
                                className="add-icon-workout-general"
                                onClick={() =>
                                  handleOpenDrawer(rowIndex + 1, colIndex + 1)
                                }
                                sx={{
                                  mr: 0.5,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          )}
                        {workouts.map((data, i) => {
                          if (
                            data?.workout_day ==
                            rowIndex * 7 + (colIndex + 1)
                          )
                            return (
                              <div
                                className="m-1"
                                style={{ position: "relative" }}
                                id={data.id + colIndex}
                                class="draggable"
                                draggable={data.editable}
                                onDragStart={(event) =>
                                  data.editable &&
                                  drag(
                                    event,
                                    data /* Pass the data you want to drag */
                                  )
                                }
                                onDragEnd={(event) =>
                                  data.editable && dragEnd(event, data)
                                }>
                                <EventCard
                                  data={data}
                                  index={i}
                                  setData={setworkouts}
                                  onDelete={handleDeleteEvent}
                                />
                              </div>
                            );
                        })}
                      </div>
                    </div>

                    {/* <div>Week {colIndex + 1}</div> */}
                    {/* <input
                  type="text"
                  value={col}
                  onChange={(e) =>
                    handleChange(rowIndex, colIndex, e.target.value)
                  }
                /> */}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <div className="container mb-2">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <div className="row">
            <h2>{state?.title}</h2>
          </div>
        </div>
      </div>
      <Table numRows={parseInt(state?.no_of_weeks)} numCols={7} />
    </div>
  );
};

export default DynamicTable;
